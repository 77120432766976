/* Basic editor styles */
.ProseMirror {
  padding-top: 2px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 6px;
  border: 1px solid #fff !important;
  border-radius: 4px;
  outline: none !important;
}
.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(255, 255, 255, 0.3);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  margin: 2rem 0;
}

.ProseMirror p.is-editor-empty:first-child:before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror a:link,
.ProseMirror a:visited {
  color: #6497f0;
}
